
import { defineComponent, PropType } from 'vue';
import { Course } from '@/models';
import { mapState } from 'vuex';
import Modal from '@/components/common/Modal.vue';

export default defineComponent({
  components: { Modal },
  data () {
    return {
      editedCourse: {} as Course
    };
  },
  emits: ['emitUpdatedCourse'],
  props: {
    course: {
      type: Object as PropType<Course>,
      required: true
    }
  },
  computed: {
    ...mapState({ languages: 'languages' })
  },
  watch: {
    course (newVal: Course) {
      this.editedCourse = JSON.parse(JSON.stringify(newVal));
    }
  },
  methods: {
    emitUpdatedCourse () {
      (this.$refs.modal as typeof Modal).closeModal();
      this.$emit('emitUpdatedCourse', this.editedCourse);
    },
    openModal () {
      (this.$refs.modal as typeof Modal).openModal();
    }
  }
});
