import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-row mb-3" }
const _hoisted_2 = { class: "col col-6" }
const _hoisted_3 = {
  class: "my-1 mr-2",
  for: "courseName"
}
const _hoisted_4 = { class: "col col-6" }
const _hoisted_5 = { class: "form-row mb-3" }
const _hoisted_6 = { class: "col col-6" }
const _hoisted_7 = {
  class: "my-1 mr-2",
  for: "courseDesc"
}
const _hoisted_8 = { class: "col col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "editCourseModal",
    ref: "modal"
  }, {
    openButton: _withCtx((props) => [
      _createElementVNode("button", _mergeProps({ ref: "openButton" }, props, { class: "invisible" }), null, 16)
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("messages.change course")), 1)
    ]),
    modalBody: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("messages.course name")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.editedCourse.name = $event)),
            id: "courseName",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.editedCourse.name]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("messages.Description:")), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("textarea", {
            class: "form-control",
            id: "courseDesc",
            style: {"min-height":"2rem"},
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.editedCourse.description = $event))
          }, null, 512), [
            [_vModelText, _ctx.editedCourse.description]
          ])
        ])
      ])
    ]),
    modalFooter: _withCtx(() => [
      _createElementVNode("button", {
        type: "submit",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitUpdatedCourse && _ctx.emitUpdatedCourse(...args))),
        class: "btn btn-primary"
      }, _toDisplayString(_ctx.$t("messages.save changes")), 1)
    ]),
    _: 1
  }, 512))
}