
import { defineComponent } from 'vue';
import CourseCard from '@/components/common/CourseCard.vue';
import { mapState } from 'vuex';
import { Course, errorPrepend } from '@/models';
import EditCourseModal from '@/components/teacher/EditCourseModal.vue';
import { showError } from '@/sweetalert2/templates.ts';

export default defineComponent({
  components: { CourseCard, EditCourseModal },
  computed: {
    ...mapState({
      courses: 'courses'
    })
  },
  data () {
    return {
      course: {} as Course
    };
  },
  methods: {
    toggleModal (course: Course) {
      this.course = course;
      (this.$refs.editModal as typeof EditCourseModal).openModal();
    },
    async updateCourse (course: Course) {
      try {
        await Course.update(course);
        await this.$store.dispatch('getCourses');
        await this.$store.dispatch('setSideNav');
      } catch (e) {
        await showError(this.$t("messages.course error change") + errorPrepend());
      }
    }
  }
});
