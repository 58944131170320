
import { defineComponent, PropType } from 'vue';
import { backendUrl, Course, Language } from '@/models';
import { mapState } from 'vuex';
import { languageMixin } from '@/mixins/languageMixin';

export default defineComponent({
  name: 'CourseCard',
  mixins: [languageMixin],
  props: {
    course: { type: Object as PropType<Course> }
  },
  emits: ['editCourse'],
  methods: {
    getImgUrl () {
      if (this.course?.languageTo) {
        const fullLang: Language | undefined = this.shortToFull(this.course.languageTo);
        return fullLang !== undefined ? `${backendUrl}/${fullLang.imageUrl}` : '';
      }
    },
    redirectToDetails () {
      this.$router.push(`/course/${this.course?.id}/`);
    }
  },
  computed:
    {
      ...mapState({ user: 'user' })
    }
});

